<template>
  <v-snackbar
      v-if="snackbarStore.message"
      v-model="snackbarStore.message.show"
      :color="snackbarStore.message.color"
  >{{ snackbarStore.message.message }}</v-snackbar>
</template>

<script setup lang="ts">
const snackbarStore = useSnackbarStore();

onMounted(() => {
  snackbarStore.showMessage();
});

</script>

<style scoped lang="scss">

</style>
